<template>
  <b-modal
    id="create-ticket-modal"
    ref="modal"
    v-model="open"
    size="lg"
    no-close-on-backdrop
    hide-footer
    centered
    title="CREATE TICKET"
  >
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form @submit.prevent="handleSubmit">
        <b-row>
          <b-col>
            <b-form-group
              label="Ticket Type"
              label-for="ticket_type"
            >
              <v-select
                v-model="form.ticket_type_id"
                :options="ticketTypes"
                :reduce="option => option.id"
                label="name"
                placeholder="Select Ticket Type"
              >
                <template v-slot:option="option">
                  {{ option.name }}
                </template>
                <template #search="{attributes}">
                  <input
                    class="vs__search"
                    style="pointer-events:none"
                    :required="!form.ticket_type_id"
                    v-bind="attributes"
                  >
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Ticket Subject"
              label-for="subject"
            >
              <b-form-input
                id="subject"
                v-model="form.subject"
                placeholder="Enter Subject"
                rows="6"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Ticket Body"
              label-for="message"
            >
              <b-form-textarea
                id="message"
                v-model="form.message"
                placeholder="Enter Ticket Content"
                rows="6"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Attachments"
              label-for="attachments"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="TerminalIcon" />
                </b-input-group-prepend>
                <b-form-file
                  id="wildcard"
                  v-model="form.file"
                  accept="image/*"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex justify-content-end mt-1 mb-1">
            <b-button
              v-can="'create-tickets'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="w-100"
              type="submit"
            >
              <feather-icon
                icon="CheckCircleIcon"
                class="mr-50"
              />
              <span class="align-middle">Create</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BModal, BFormTextarea, BButton, BInputGroup, BInputGroupPrepend, VBModal, BForm, BFormInput, BOverlay, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const TicketRepository = RepositoryFactory.get('ticket')

function initialState() {
  return {
    open: false,
    loading: false,
    form: {},
    ticketTypes: [],
  }
}

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BFormFile,
    BForm,
    BFormInput,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal() {
      Object.assign(this.$data, initialState())
      this.open = true
      this.getTicketTypes()
    },
    async getTicketTypes() {
      try {
        this.loading = true
        this.ticketTypes = localStorage.getItem('ticket_types') ? JSON.parse(localStorage.getItem('ticket_types')) : (await TicketRepository.getTicketTypes()).data.data
        if (!localStorage.getItem('ticket_types')) localStorage.setItem('ticket_types', JSON.stringify(this.ticketTypes))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async handleSubmit() {
      try {
        this.loading = true
        const data = {
          ticket_type_id: this.form.ticket_type_id,
          subject: this.form.subject,
          message: this.form.message,
        }
        if (this.form.file) data.file = this.form.file
        const res = await TicketRepository.createTicket(data)
        if (res.status === 201) {
          if (typeof res.data === 'string') {
            if (res.data.includes('Unknown: file')) {
              res.data = res.data.replace(/<\/?[^>]+(>|$)/g, '')
              res.data = JSON.parse(res.data.replace("Notice:  Unknown: file created in the system's temporary directory in Unknown on line 0", ''))
            }
          }
          this.showSuccessMessage('Ticket Added Successfully')
          this.open = false
          await this.$router.push(`/tickets/${res.data.data.id}`)
        } else {
          this.showErrorMessage("Couldn't add ticket")
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';
</style>
